<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="pt-5 px-6">
      <trac-back-button>Inventory Transfers</trac-back-button>
      <div class="flex justify-between items-center py-4">
        <h2 class="font-bold capitalize">{{ transfer.name }}</h2>
        <div class="flex gap-x-4">
          <trac-button
            @button-clicked="acceptTransfer"
            v-if="
              transfer.status === 'in_transit' && transfer.status !== 'on_hold'
            "
          >
            Accept Order
          </trac-button>
          <trac-button
            v-if="transfer.status !== 'on_hold'"
            @button-clicked="holdTransfer"
          >
            Hold Order
          </trac-button>
          <trac-button
            v-if="transfer.status === 'on_hold'"
            @button-clicked="releaseTransfer"
          >
            Release Order
          </trac-button>
          <trac-button @button-clicked="$router.back()">Close</trac-button>
        </div>
      </div>
      <div class="grid grid-cols-12 my-10 gap-5">
        <div
          class="col-span-4 shadow-md py-5 px-6"
          style="box-shadow: 0px 0px 20px 0px rgba(22, 120, 176, 6%)"
        >
          <div class="grid grid-cols-2 mb-2">
            <p class="text-xs col-span-1 text-primaryGray font-medium">
              Source store:
            </p>
            <span class="text-xs capitalize col-span-1">{{
              transfer.source.store_name
            }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <p class="text-xs col-span-1 text-primaryGray font-medium">
              Destination store:
            </p>
            <span class="text-xs capitalize col-span-1">{{
              transfer.destination.store_name
            }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <p class="text-xs col-span-1 text-primaryGray font-medium">
              Ordered by:
            </p>
            <span
              v-if="transfer.initiated_by && transfer.initiated_by.name"
              class="text-xs capitalize col-span-1"
              >{{ transfer.initiated_by.name }}</span
            >
          </div>
        </div>
        <div
          class="col-span-4 shadow-md py-5 px-6"
          style="box-shadow: 0px 0px 20px 0px rgba(22, 120, 176, 6%)"
        >
          <div class="grid grid-cols-2 mb-2">
            <p class="text-xs col-span-1 text-primaryGray font-medium">
              Transfer status:
            </p>
            <span
              class="text-xs capitalize col-span-1"
              :class="
                transfer.status === 'Transferred'
                  ? 'text-secondaryGreen'
                  : transfer.status === 'on_hold'
                  ? 'text-primaryBlue'
                  : transfer.status === 'in_transit'
                  ? 'text-yellow-500'
                  : 'text-secondaryRed'
              "
            >
              {{ transfer.status ? transfer.status.replace("_", " ") : "" }}
            </span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <p class="text-xs col-span-1 text-primaryGray font-medium">
              Transfer date:
            </p>
            <span class="text-xs col-span-1">{{
              moment(transfer.transfer_date).format("DD/MM/YYYY")
            }}</span>
          </div>
          <div class="grid grid-cols-2 mb-2">
            <p class="text-xs col-span-1 text-primaryGray font-medium">
              Received date:
            </p>
            <span
              v-if="
                transfer.received_date &&
                !['in_transit', 'on_hold', 'Held', 'revert'].includes(
                  transfer.status
                )
              "
              class="text-xs col-span-1"
            >
              {{ moment(transfer.received_date).format("DD/MM/YYYY") }}
            </span>
            <span class="text-xs col-span-1" v-else>N/A</span>
          </div>
        </div>
      </div>
      <div class="w-full my-20 px-4">
        <h3 class="font-bold my-2">Items</h3>
        <table class="w-full">
          <thead class="bg-blue-100">
            <tr>
              <th class="text-left p-2 text-xs">NO.</th>
              <th class="text-left p-2 text-xs">Item</th>
              <th class="text-left p-2 text-xs">SKU</th>
              <th class="text-left p-2 text-xs">Category</th>
              <th class="text-left p-2 text-xs">Units Sent</th>
              <th class="text-left p-2 text-xs">Units Received</th>
              <th class="text-left p-2 text-xs">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr
              :class="i % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(item, i) in transfer.items"
              :key="i"
            >
              <td class="p-2 text-xs capitalize">
                <span>{{ i + 1 }}</span>
              </td>
              <td class="p-2 text-sm capitalize font-bold">
                {{ item.title }}
                <span v-if="item.name && item.parent_id"
                  >- {{ item.name }}</span
                >
              </td>
              <td class="p-2 text-xs">{{ item.barcode }}</td>
              <td class="p-2 text-xs capitalize">
                {{ item.categories ? item.categories.name : "" }}
              </td>
              <td class="p-2 text-xs">
                {{ item.quantity_sent || item.sent_quantity }}
              </td>
              <td class="p-2 text-xs">
                {{ item.quantity_recieved || item.items_delivered }}
              </td>
              <td class="p-2 text-xs font-bold">
                {{ item.salePrice | formatPrice }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="flex justify-end"
        v-if="
          !['on_hold', 'fully_received', 'sent_and_recieved'].includes(
            transfer.status
          )
        "
      >
        <trac-button
          @button-clicked="cancelTransfer"
          v-if="!['revert'].includes(transfer.status)"
        >
          Cancel
        </trac-button>
      </div>
    </main>
  </div>
</template>

<script>
import { GET_LOCAL_DB_DATA } from "../../../browser-db-config/localStorage";
import moment from "moment";
import { eventBus } from "./../../../main";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      transfer: null,
    };
  },
  async created() {
    this.isLoading = true;
    this.fetchTransfers();
    await this.fetchAllProducts();
    this.isLoading = false;
  },
  methods: {
    fetchTransfers() {
      console.log(GET_LOCAL_DB_DATA("transfer-details"), "transfer details");
      this.transfer = { ...(GET_LOCAL_DB_DATA("transfer-details") ?? {}) };
    },
    async holdTransfer() {
      this.isLoading = true;
      const payload = {
        _id: this.transfer._id,
      };

      await this.$store.dispatch("HOLD_TRANSFER", payload);
      const res = this.$store.getters["GET_TRANSFER_RES"];
      this.$store.commit("SEND_INVENTORY_TRANSFER_RES", null);

      if (res.status) {
        setTimeout(() => {
          this.$router.back();
        }, 1200);
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.response?.data?.message });
      }
      this.isLoading = false;
    },
    async releaseTransfer() {
      this.isLoading = true;
      const payload = {
        transfer_id: this.transfer._id,
        status: "release",
      };

      await this.$store.dispatch("RELEASE_TRANSFER", payload);
      const res = this.$store.getters["GET_TRANSFER_RES"];
      this.$store.commit("SEND_INVENTORY_TRANSFER_RES", null);

      if (res.status) {
        setTimeout(() => {
          this.$router.back();
        }, 1200);
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res?.response?.data?.message });
      }
      this.isLoading = false;
    },
    async cancelTransfer() {
      this.isLoading = true;
      const payload = {
        transfer_id: this.transfer._id,
        status: "revert",
      };

      await this.$store.dispatch("CANCEL_TRANSFER", payload);
      const res = this.$store.getters["GET_TRANSFER_RES"];
      this.$store.commit("SEND_INVENTORY_TRANSFER_RES", null);

      if (res.status) {
        setTimeout(() => {
          this.$router.back();
        }, 1200);
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async acceptTransfer() {
      this.isLoading = true;
      const payload = {
        destination: {
          store_id: this.transfer.destination.store_name,
          store_name: this.transfer.destination.store_id,
        },
        transfer_id: this.transfer._id,
        items: this.transfer.items.map((item) => {
          return {
            item_id: item.id,
            quantity_sent: item.sent_quantity,
            quantity_recieved: item.sent_quantity,
            reason: "",
          };
        }),
      };

      await this.$store.dispatch("ACCEPT_TRANSFER", payload);
      const res = this.$store.getters["GET_TRANSFER_RES"];
      this.$store.commit("SEND_INVENTORY_TRANSFER_RES", null);
      this.isLoading = false;

      if (res.status) {
        setTimeout(() => {
          this.$router.back();
        }, 1200);
      } else {
        eventBus.$emit("trac-alert", { message: res.data.error.message });
        // alert(res.message);
      }
    },
    async fetchAllProducts() {
      const res = await this.$store.dispatch("FETCH_PRODUCTS_V2", {
        params: { limit: 1000 },
      });

      if (res.status) {
        const products = res.data?.products || [];
        this.transfer.items = this.transfer.items
          .map((item) => {
            return {
              ...item,
              ...(products.find(
                (product) =>
                  product.id === item.id ||
                  product?.variations?.find((v) => v.id === item.id)
              ) ?? {}),
            };
          })
          .filter((prod) => prod.id);
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
  },
};
</script>

<style></style>
