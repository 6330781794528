var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[(_vm.isLoading)?_c('trac-loading'):_vm._e(),_c('main',{staticClass:"pt-5 px-6"},[_c('trac-back-button',[_vm._v("Inventory Transfers")]),_c('div',{staticClass:"flex justify-between items-center py-4"},[_c('h2',{staticClass:"font-bold capitalize"},[_vm._v(_vm._s(_vm.transfer.name))]),_c('div',{staticClass:"flex gap-x-4"},[(
            _vm.transfer.status === 'in_transit' && _vm.transfer.status !== 'on_hold'
          )?_c('trac-button',{on:{"button-clicked":_vm.acceptTransfer}},[_vm._v(" Accept Order ")]):_vm._e(),(_vm.transfer.status !== 'on_hold')?_c('trac-button',{on:{"button-clicked":_vm.holdTransfer}},[_vm._v(" Hold Order ")]):_vm._e(),(_vm.transfer.status === 'on_hold')?_c('trac-button',{on:{"button-clicked":_vm.releaseTransfer}},[_vm._v(" Release Order ")]):_vm._e(),_c('trac-button',{on:{"button-clicked":function($event){return _vm.$router.back()}}},[_vm._v("Close")])],1)]),_c('div',{staticClass:"grid grid-cols-12 my-10 gap-5"},[_c('div',{staticClass:"col-span-4 shadow-md py-5 px-6",staticStyle:{"box-shadow":"0px 0px 20px 0px rgba(22, 120, 176, 6%)"}},[_c('div',{staticClass:"grid grid-cols-2 mb-2"},[_c('p',{staticClass:"text-xs col-span-1 text-primaryGray font-medium"},[_vm._v(" Source store: ")]),_c('span',{staticClass:"text-xs capitalize col-span-1"},[_vm._v(_vm._s(_vm.transfer.source.store_name))])]),_c('div',{staticClass:"grid grid-cols-2 mb-2"},[_c('p',{staticClass:"text-xs col-span-1 text-primaryGray font-medium"},[_vm._v(" Destination store: ")]),_c('span',{staticClass:"text-xs capitalize col-span-1"},[_vm._v(_vm._s(_vm.transfer.destination.store_name))])]),_c('div',{staticClass:"grid grid-cols-2 mb-2"},[_c('p',{staticClass:"text-xs col-span-1 text-primaryGray font-medium"},[_vm._v(" Ordered by: ")]),(_vm.transfer.initiated_by && _vm.transfer.initiated_by.name)?_c('span',{staticClass:"text-xs capitalize col-span-1"},[_vm._v(_vm._s(_vm.transfer.initiated_by.name))]):_vm._e()])]),_c('div',{staticClass:"col-span-4 shadow-md py-5 px-6",staticStyle:{"box-shadow":"0px 0px 20px 0px rgba(22, 120, 176, 6%)"}},[_c('div',{staticClass:"grid grid-cols-2 mb-2"},[_c('p',{staticClass:"text-xs col-span-1 text-primaryGray font-medium"},[_vm._v(" Transfer status: ")]),_c('span',{staticClass:"text-xs capitalize col-span-1",class:_vm.transfer.status === 'Transferred'
                ? 'text-secondaryGreen'
                : _vm.transfer.status === 'on_hold'
                ? 'text-primaryBlue'
                : _vm.transfer.status === 'in_transit'
                ? 'text-yellow-500'
                : 'text-secondaryRed'},[_vm._v(" "+_vm._s(_vm.transfer.status ? _vm.transfer.status.replace("_", " ") : "")+" ")])]),_c('div',{staticClass:"grid grid-cols-2 mb-2"},[_c('p',{staticClass:"text-xs col-span-1 text-primaryGray font-medium"},[_vm._v(" Transfer date: ")]),_c('span',{staticClass:"text-xs col-span-1"},[_vm._v(_vm._s(_vm.moment(_vm.transfer.transfer_date).format("DD/MM/YYYY")))])]),_c('div',{staticClass:"grid grid-cols-2 mb-2"},[_c('p',{staticClass:"text-xs col-span-1 text-primaryGray font-medium"},[_vm._v(" Received date: ")]),(
              _vm.transfer.received_date &&
              !['in_transit', 'on_hold', 'Held', 'revert'].includes(
                _vm.transfer.status
              )
            )?_c('span',{staticClass:"text-xs col-span-1"},[_vm._v(" "+_vm._s(_vm.moment(_vm.transfer.received_date).format("DD/MM/YYYY"))+" ")]):_c('span',{staticClass:"text-xs col-span-1"},[_vm._v("N/A")])])])]),_c('div',{staticClass:"w-full my-20 px-4"},[_c('h3',{staticClass:"font-bold my-2"},[_vm._v("Items")]),_c('table',{staticClass:"w-full"},[_vm._m(0),_c('tbody',_vm._l((_vm.transfer.items),function(item,i){return _c('tr',{key:i,class:i % 2 === 0 ? '' : 'bg-gray-100'},[_c('td',{staticClass:"p-2 text-xs capitalize"},[_c('span',[_vm._v(_vm._s(i + 1))])]),_c('td',{staticClass:"p-2 text-sm capitalize font-bold"},[_vm._v(" "+_vm._s(item.title)+" "),(item.name && item.parent_id)?_c('span',[_vm._v("- "+_vm._s(item.name))]):_vm._e()]),_c('td',{staticClass:"p-2 text-xs"},[_vm._v(_vm._s(item.barcode))]),_c('td',{staticClass:"p-2 text-xs capitalize"},[_vm._v(" "+_vm._s(item.categories ? item.categories.name : "")+" ")]),_c('td',{staticClass:"p-2 text-xs"},[_vm._v(" "+_vm._s(item.quantity_sent || item.sent_quantity)+" ")]),_c('td',{staticClass:"p-2 text-xs"},[_vm._v(" "+_vm._s(item.quantity_recieved || item.items_delivered)+" ")]),_c('td',{staticClass:"p-2 text-xs font-bold"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.salePrice))+" ")])])}),0)])]),(
        !['on_hold', 'fully_received', 'sent_and_recieved'].includes(
          _vm.transfer.status
        )
      )?_c('div',{staticClass:"flex justify-end"},[(!['revert'].includes(_vm.transfer.status))?_c('trac-button',{on:{"button-clicked":_vm.cancelTransfer}},[_vm._v(" Cancel ")]):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-blue-100"},[_c('tr',[_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("NO.")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Item")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("SKU")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Category")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Units Sent")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Units Received")]),_c('th',{staticClass:"text-left p-2 text-xs"},[_vm._v("Price")])])])
}]

export { render, staticRenderFns }